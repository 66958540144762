<template>
    <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block variant="warning" @click="showCollapse = !showCollapse">
                {{ title }}
                <b-badge variant="warning">{{ badge }}</b-badge>
            </b-button>
        </b-card-header>
        <b-collapse :id="randomString()" role="tabpanel" v-model="showCollapse">
            <b-card-body>
                <slot/>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
    import {randomString} from '@/util/text';

    export default {
        name: "MobileAdminCollapseTab",
        props: {
            title: {
                type: String,
                required: true
            },
            badge: {
                default: null
            }
        },
        data: () => ({
            showCollapse: false
        }),
        methods: {
            randomString,
        }
    }
</script>

<style scoped>
    header.card-header .btn {
        background-color: #f0b967;
    }
</style>